import React, { useState, useEffect } from "react";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";
import "./App.css";
import DesciptionBlock from "./components/DescriptionBlock/DescriptionBlock";
import LinkedinButton from "./components/LinkedinButton/LinkedinButton";

function App() {
  const [isUnderConstruction, setIsUnderConstruction] = useState(true);

  if (isUnderConstruction) {
    return <UnderConstruction />;
  }

  return (
    <div className="animated-background ">
      <h1>EDUARDO ANDRES VARON</h1>
      <h2>Fullstack Developer</h2>
      <DesciptionBlock />
      <LinkedinButton></LinkedinButton>
    </div>
  );
}

export default App;
