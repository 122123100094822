import React from "react";
import "./LinkedinButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const LinkedinButton = () => {
  return (
    <a
      href="https://www.linkedin.com/in/eduardoandresvaron"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="linkedin-button">
        <FontAwesomeIcon icon={faLinkedinIn} className="linkedin-icon" />
        <FontAwesomeIcon
          icon="fa-brands fa-linkedin-in"
          className="linkedin-icon"
        />
        LinkedIn
      </button>
    </a>
  );
};

export default LinkedinButton;
