import React from "react";
import "./DescriptionBlock.css";

const DesciptionBlock = () => {
  return (
    <div className="glass-morphism">
      <p>
        Hi, I'm Eduardo Andres Varon, a passionate Fullstack Developer with
        experience in building web applications using modern technologies. I
        love coding and solving complex problems.Hi, I'm Eduardo Andres Varon, a
        passionate Fullstack Developer with experience in building web
        applications using modern technologies. I love coding and solving
        complex problems.
      </p>
    </div>
  );
};

export default DesciptionBlock;
